import(/* webpackMode: "eager" */ "/opt/build/repo/apps/docs/app/home.css");
;
import(/* webpackMode: "eager", webpackExports: ["ComponentsCard"] */ "/opt/build/repo/apps/docs/app/ui/home-page/ComponentsCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GithubButton"] */ "/opt/build/repo/apps/docs/app/ui/home-page/GithubButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["IconsCard"] */ "/opt/build/repo/apps/docs/app/ui/home-page/IconsCard.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/apps/docs/app/ui/layout/wrapper/wrapper.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/apps/docs/components/button/LinkButton.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/apps/docs/components/icon/assets/accessible.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/apps/docs/components/icon/assets/arrow.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/apps/docs/components/icon/assets/check.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/apps/docs/components/icon/assets/code.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/apps/docs/components/icon/assets/collapse.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/apps/docs/components/icon/assets/component.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/apps/docs/components/icon/assets/dark-mode.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/apps/docs/components/icon/assets/empty-component.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/apps/docs/components/icon/assets/error.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/apps/docs/components/icon/assets/external-link.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/apps/docs/components/icon/assets/font-size.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/apps/docs/components/icon/assets/github.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/apps/docs/components/icon/assets/info.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/apps/docs/components/icon/assets/international.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/apps/docs/components/icon/assets/line-height.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/apps/docs/components/icon/assets/margin.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/apps/docs/components/icon/assets/message.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/apps/docs/components/icon/assets/npm.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/apps/docs/components/icon/assets/product-menu.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/apps/docs/components/icon/assets/right-angle.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/apps/docs/components/icon/assets/select-arrow.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/apps/docs/components/icon/assets/tokens.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/apps/docs/components/icon/assets/typescript.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/apps/docs/components/icon/assets/wai-aria.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/apps/docs/components/icon/assets/warning.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/apps/docs/components/icon/Icon.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/next@14.2.15_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
